<template>
  <main>
    <sqr-hero title="forbidden_title" subtitle="forbidden_subtitle" color="danger" is-bold/>
  </main>
</template>

<script>
export default {
  name: 'Forbidden',
  data() {
    return {
      status: 'default'
    };
  }
};
</script>
